.customizable-field {
  --customizable-field-closed-height: 52px;

  border: 1px solid var(--chakra-colors-gray-300);
  border-radius: 6px;
  overflow: hidden;
  max-height: var(--customizable-field-closed-height);
  transition: max-height 0.3s ease;
  margin-bottom: 10px;

  &.opened {
    max-height: 300px;
  }

  &.enabled {
    border-color: var(--chakra-colors-blue-500);
  }

  &.expandable {
    .header {
      cursor: pointer;

      &:hover {
        background-color: var(--chakra-colors-gray-100);
      }
    }
  }

  .header {
    align-items: center;
    height: var(--customizable-field-closed-height);
  }
}

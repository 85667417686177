#area-switcher {
  --width: 280px;

  .label {
    color: var(--chakra-colors-gray-200);
  }

  & > button {
    width: var(--width);

    color: var(--chakra-colors-gray-300);
    background-color: rgba(0, 0, 0, 0.3);

    border: 1px solid var(--chakra-colors-gray-600);
    border-radius: var(--chakra-radii-md);

    &:hover,
    &[data-active] {
      background-color: var(--chakra-colors-gray-700);
    }

    span:first-child {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
    }
  }

  .menu {
    background-color: var(--chakra-colors-gray-800);
    border: 1px solid var(--chakra-colors-gray-600);
    border-radius: var(--chakra-radii-md);
    min-width: var(--width);
    overflow: auto;

    a {
      color: var(--chakra-colors-gray-300);
      background-color: transparent;

      &:hover {
        background-color: var(--chakra-colors-gray-700);
      }
    }

    &::-webkit-scrollbar {
      width: 0.5rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--chakra-colors-gray-600);
      border-radius: 0.7rem;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 0.7rem;
    }
  }
}

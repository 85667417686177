.modeled-items {
  align-items: center;
  background-color: white;
  flex: 1;

  .column {
    overflow: hidden;
    transition:
      width 0.3s ease,
      margin 0.3s ease;

    &.diagram {
      width: var(--inspection-sheet-diagram-width);
      background-color: white;

      & > div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &.hidden {
      width: 0;
      margin: 0;
    }
  }

  .no-data {
    background-color: var(--chakra-colors-gray-50);
    width: var(--inspection-sheet-diagram-width);
    overflow: hidden;
    transition:
      width 0.3s ease,
      margin 0.3s ease;
    white-space: nowrap;

    &.hidden {
      width: 0;
      margin: 0;
    }
  }

  .title {
    padding: var(--chakra-space-4);
    gap: var(--chakra-space-4);
    align-items: center;
    height: 100%;
    background-color: var(--chakra-colors-primary-50);

    * {
      font-size: var(--chakra-fontSizes-sm);
    }

    .popover-editing {
      display: flex;
      flex-direction: column;

      p {
        writing-mode: vertical-lr;
        font-size: var(--chakra-fontSizes-xl);
      }
    }
  }
}

.pill {
  --main-color: var(--chakra-colors-gray-500);

  position: relative;
  display: inline-block;

  border: 1px solid var(--main-color);
  border-radius: var(--chakra-radii-lg);

  color: var(--main-color);
  font-weight: 700;
  font-size: var(--chakra-fontSizes-xs);
  text-transform: initial;
  line-height: 1rem;

  padding: 0 5px;

  transition: all ease-in-out 0.2s;

  svg {
    position: absolute;
    top: -50%;
    right: -50%;
    transform: translate(-75%, 25%);
  }

  &.red {
    --main-color: var(--chakra-colors-red-400);
    background-color: var(--chakra-colors-red-50);
  }

  &.blue {
    --main-color: var(--chakra-colors-blue-400);
    background-color: var(--chakra-colors-blue-50);
  }

  &.green {
    --main-color: var(--chakra-colors-green-500);
    background-color: var(--chakra-colors-green-50);
  }
}

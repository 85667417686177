html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#inner-container {
  height: 100svh;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button.upload-file-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.nav-link {
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
}
.nav-link.active {
  border-bottom-color: var(--chakra-colors-yellow-300);
}

.hatsuly-date-picker .rdp-day_selected,
.hatsuly-date-picker .rdp-day_selected:focus-visible,
.hatsuly-date-picker .rdp-day_selected:hover {
  background-color: var(--chakra-colors-primary-500);
}
.hatsuly-date-picker.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
  background-color: var(--chakra-colors-primary-100);
}

.inspection-sheet-input-box::before {
  content: '';
  position: absolute;
  background-color: green;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.nav-link-tablet {
  border-left: 5px solid transparent;
}
.nav-link-tablet.active {
  border-left-color: var(--chakra-colors-yellow-300);
  background-color: #f6e05e20;
}
.nav-links-tablet {
  transform: translateX(-100%);
  transition: 0.2s ease;
}
.nav-links-tablet.active {
  transform: translateX(0);
}

.icon-black {
  line,
  circle,
  path {
    stroke: var(--chakra-colors-secondary-700);
  }
}

#area-edit-type {
  --radius: 5px;

  justify-content: center;

  div {
    border: 1px solid var(--gray-200, #e2e8f0);
    border-radius: var(--radius);
    overflow: hidden;

    button {
      background-color: initial;
      color: var(--chakra-colors-gray-500, #718096);
      padding-left: var(--chakra-space-6);
      padding-right: var(--chakra-space-7);
      width: 130px;
      font-size: var(--chakra-fontSizes-md);
      border-radius: 0;

      background-size: 200%;

      transition:
        color ease-in-out 0.3s,
        background-position 0.3s,
        border-radius ease-in-out 0.3s;

      &.active {
        color: white;
      }

      &:first-child {
        background-image: linear-gradient(to right, var(--chakra-colors-primary-500) 50%, #fff 50%);
        background-position: 100%;

        &.active {
          background-position: 200%;
        }
      }

      &:nth-child(2) {
        background-image: linear-gradient(to right, #fff 50%, var(--chakra-colors-primary-500) 50%);
        background-position: 200%;

        &.active {
          background-position: 100%;
        }
      }
    }
  }
}

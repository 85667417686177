#panel-container {
  overflow: hidden;
  color: var(--chakra-colors-secondary-400);
  font-size: var(--chakra-fontSizes-xs);

  position: absolute;
  top: var(--chakra-space-2);
  right: 0;

  height: calc(100% - var(--chakra-space-4));
  gap: var(--chakra-space-2);
  align-items: start;

  button.collapse-all-panel {
    svg {
      transition: all ease-in-out 0.1s;
    }

    &.collapsed {
      svg {
        transform: rotate(90deg);
      }
    }
  }

  .panel {
    width: 100%;
    background-color: var(--chakra-colors-gray-800);
    border-top-left-radius: var(--chakra-radii-md);
    border-bottom-left-radius: var(--chakra-radii-md);

    .panel-header {
      background-color: var(--chakra-colors-gray-900);
      border-top-left-radius: var(--chakra-radii-md);
      transition: all ease-in-out 0.2s;
      flex: 0;

      &:hover {
        background-color: var(--chakra-colors-gray-700);
      }

      svg {
        transition: all ease-in-out 0.2s;
      }
    }

    .collapsed {
      .panel-header {
        border-bottom-left-radius: var(--chakra-radii-md);
        svg {
          transform: rotate(90deg);
        }
      }
    }

    .inner-container {
      padding: var(--chakra-space-3);
      width: 100%;
    }

    &.tools-panel {
      /* Hide button container if there's no button to show */
      display: none;
      &:has(div > button) {
        display: flex;
      }

      & > div:only-child {
        border-top-left-radius: var(--chakra-radii-md);
      }

      .panel-header {
        /* tools panel is always attached to the buttons panel */
        border-bottom-left-radius: 0 !important;
        svg {
          /* To ensure it rotates towards the correct direction */
          transform: rotate(360deg);
        }
      }

      .collapsed {
        .panel-header {
          svg {
            transform: rotate(270deg);
          }
        }
      }

      .tool-buttons {
        background-color: var(--chakra-colors-gray-600);
        width: 100%;
        padding: var(--chakra-space-4);
        border-bottom-left-radius: var(--chakra-radii-md);
      }
    }
  }
}

#camera-toolbar {
  .toolbar-button:first-child > div {
    border-top-left-radius: var(--chakra-radii-md);
    border-bottom-left-radius: var(--chakra-radii-md);
  }

  .toolbar-button:last-child > div {
    border-top-right-radius: var(--chakra-radii-md);
    border-bottom-right-radius: var(--chakra-radii-md);
  }
}

body {
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
}

@media print {
  #inspection-sheet-container {
    overflow: initial;
  }

  #project-navbar {
    display: none;
  }

  #inspection-sheet-action-bar {
    display: none;
  }

  .inspection-sheet-table-header {
    display: none;
    position: relative;
    top: 0px;
  }

  #inspection-sheet {
    height: auto;

    .scrollable-container {
      overflow: initial;
    }

    .data-rows {
      break-inside: avoid;
    }

    .construction-properties svg,
    .inspection-sheet-table-header {
      display: none;
    }

    /* 
     * This is to prevent the first inspection area to be pushed down to new page.
     * Unfortunately, there's no way to specifically target the first inspection area, 
     * so we have to target the divs directly and inspection area rows start from the third div.
     **/
    .scrollable-container > div:nth-of-type(2) {
      page-break-before: avoid !important;
      break-before: avoid !important;
    }

    .inspection-area-row {
      page-break-before: always;
      flex-direction: column;
      margin-bottom: var(--chakra-space-20);

      .inspection-sheet-table-header {
        display: flex;

        & > div:first-child {
          width: 185px;
        }
      }

      .inspection-area-title {
        padding: var(--chakra-space-2) 0 0 0;
      }

      .inspection-area-properties {
        padding-top: 0;
        padding-bottom: var(--chakra-space-2);

        & > div {
          &:first-child {
            margin-bottom: 0;

            p {
              margin-right: var(--chakra-space-12);
            }
          }

          p {
            span {
              width: 80px;
              height: initial;
              margin-right: var(--chakra-space-4);
            }
          }
        }
      }

      .inspection-area-data-rows {
        .data-rows {
          break-inside: avoid;
        }

        input {
          border: none;
        }
      }

      .no-data {
        min-height: 200px;

        a {
          display: none;
        }
      }

      .grid-value-box .pill svg {
        display: none;
      }

      svg {
        display: none;
      }

      /*
       * Handles the active state of the inspection area.
       */
      &.active {
        --header-bg-active: var(--header-bg);
      }
    }

    .saving-indicator {
      display: none;
    }
  }
}

button.area-list-grabbable-item {
  position: absolute;
  top: 0;
  left: 0;

  height: 60px;

  background-color: white;
  font-weight: normal;
  justify-content: start;
  cursor: grab;

  padding-top: 24px;
  padding-bottom: 20px;

  &.grabbed {
    z-index: 3000;

    cursor: grabbing;
    border: 1px solid var(--chakra-colors-blue-200, #186fb9);
    border-radius: 5px;
    opacity: 0.8;

    &:hover {
      background-color: white;
    }
  }
}

#edit-inspection-area-container {
  --radius: 5px;
  --header-height: 60px;

  flex-direction: column;
  gap: var(--chakra-space-3);

  .item {
    position: relative;
    padding-top: var(--header-height);

    border-radius: var(--radius);
    border: 1px solid var(--chakra-colors-blue-200, #186fb9);
    max-height: 368px;

    transition: max-height ease-in-out 0.3s;

    &.selected {
      border-color: var(--chakra-colors-orange-400, #fefcbf);
    }

    &.dragging {
      border-style: dashed;

      & > .header > button {
        background-color: var(--chakra-colors-blue-50);
      }
    }

    input {
      border-width: 0;
      border-bottom-width: 1px;
      border-radius: 0;
      box-shadow: none;
      padding: 0;
    }

    .header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-color: white;

      justify-content: end;

      border-radius: var(--radius);
      padding-top: var(--chakra-space-3);
      padding-bottom: var(--chakra-space-2);
      padding-left: var(--chakra-space-4);
      padding-right: var(--chakra-space-4);

      & > button {
        left: -28px;
        width: 100%;

        transition: left ease-in-out 0.3s;

        border-radius: 0;

        svg {
          opacity: 0;

          transition: opacity ease-in-out 0.3s;
        }

        &:active,
        &:hover {
          color: var(--chakra-colors-blue-200);
          background-color: var(--chakra-colors-blue-50);
        }

        span.text {
          display: block;
          width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
        }
      }

      & > input {
        position: relative;

        font-size: var(--chakra-fontSizes-md);

        transition: padding ease-in-out 0.3s;
      }
    }

    .content {
      background: var(--gray-50, #f7fafc);
      padding-top: var(--chakra-space-3);
      padding-bottom: var(--chakra-space-3);
      flex-direction: column;
      border-bottom-left-radius: var(--radius);
      border-bottom-right-radius: var(--radius);

      .input-row {
        position: relative;
        align-items: center;
        width: 100%;
        padding-left: var(--chakra-space-6);
        padding-right: var(--chakra-space-6);
        padding-top: var(--chakra-space-2);

        p {
          color: var(--primary-700, #186fb9);
          width: 100px;
          white-space: nowrap;
        }

        input {
          height: var(--chakra-sizes-8);
        }
      }
    }
  }

  /**
   * Area list in ordering mode
   */
  &.edit-type-order {
    .item {
      max-height: 60px;
      overflow: hidden;

      .header {
        padding: 0;
        height: 60px;
        align-items: center;

        & > button {
          left: 0;

          svg {
            opacity: 1;
          }
        }

        & > input {
          padding-left: 28px;
        }

        &.order {
          z-index: 20;
        }

        &.edit {
          z-index: 10;
        }
      }
    }
  }

  /**
   * Area list in editing mode
   */
  &.edit-type-edit {
    .item {
      .header {
        & > button {
          opacity: 0;
          pointer-events: none;
        }

        &.order {
          z-index: 10;
        }

        &.edit {
          z-index: 20;
        }
      }

      & > input {
        padding-left: 0px;
      }
    }
  }
}

.navbar-hamburger {
  color: #fff;
  width: 250px;

  .chakra-menu__group__title {
    color: #fff;
  }

  a,
  button {
    color: #fff;
    background-color: var(--chakra-colors-gray-600);
    border-radius: 0;
    font-weight: normal;
    font-size: 1rem;
    padding-left: 39px;

    &:disabled {
      color: var(--chakra-colors-gray-300);
    }

    &.has-submenu {
      padding-left: var(--chakra-space-3);
    }

    .chakra-menu__icon-wrapper {
      width: 26px;
    }

    &:hover {
      background-color: var(--chakra-colors-gray-500);
    }
  }

  .chakra-menu__group__title {
    font-size: 1rem;
    color: var(--chakra-colors-gray-300);
  }

  /* Override for sub-menu */
  .chakra-menu__menu-list {
    a,
    button {
      padding-left: var(--chakra-space-3);
    }
  }
}

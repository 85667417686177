#inspection-sheet {
  margin: 0;
  width: 100%;
  height: 100svh;
  background-color: black;
  user-select: none;
  gap: 0;

  .scrollable-container {
    overflow: auto;
    grid-template-rows: auto auto auto 1fr;
    flex: 1;
  }

  .construction-properties {
    background-color: var(--chakra-colors-gray-100);
    padding: var(--chakra-space-4) var(--chakra-space-8);
    border-radius: var(--chakra-radii-md);
    flex-direction: column;
  }

  .data-rows {
    flex: 1;
    border-bottom: 2px solid var(--chakra-colors-gray-200);

    &:last-of-type {
      border-bottom-width: 0;
    }
  }

  #inspection-sheet-action-bar {
    z-index: 120;
  }

  .inspection-sheet-table-header {
    background: var(--chakra-colors-gray-100);

    width: 100%;

    position: sticky;
    z-index: 9;
    top: 90px;

    padding: var(--chakra-space-4);

    font-weight: bold;
    color: black;
    text-align: center;
    font-size: var(--chakra-fontSizes-md);

    & > div:first-child {
      flex-shrink: 0;
      width: var(--inspection-sheet-first-column-width);
    }

    .column {
      flex-shrink: 0;
      transition:
        width ease 0.3s,
        margin ease 0.3s;
      overflow: hidden;
      white-space: nowrap;

      &.hidden {
        width: 0;
        margin: 0;
      }
    }
  }

  .inspection-area-data-rows {
    hr {
      border-color: var(--chakra-colors-gray-300);
    }
  }

  .inspection-area-row {
    --header-bg: var(--chakra-colors-primary-50);
    --header-bg-active: var(--chakra-colors-yellow-100);

    flex: 1;
    z-index: 5;
    scroll-margin-top: 56px;
    flex-direction: column;

    &.active {
      .inspection-area-header {
        background-color: var(--header-bg-active);
      }
    }

    .inspection-area-header {
      width: 100%;
      height: 90px;

      position: sticky;
      top: 0;
      z-index: 10;

      padding: var(--chakra-space-4);
      background-color: var(--header-bg);

      border-top: 3px solid var(--chakra-colors-gray-300);
      border-bottom: 1px solid var(--chakra-colors-gray-300);

      .inspection-area-title {
        text-align: left;
        line-height: 1.5rem;

        vertical-align: middle;
        align-items: center;

        gap: var(--chakra-space-3);
        height: 100%;
        width: 700px;
        padding-left: var(--chakra-space-12);

        border-right: 1px solid rgba(0, 0, 0, 0.2);

        span {
          font-size: var(--chakra-fontSizes-2xl);
          font-weight: bold;
        }

        svg {
          cursor: pointer;
        }
      }

      .inspection-area-properties {
        vertical-align: middle;
        text-align: center;
        height: 100%;
        flex: 1;
        padding-left: var(--chakra-space-8);
        grid-template-rows: repeat(2, 1fr);
        grid-auto-flow: column;
        justify-content: start;
        column-gap: var(--chakra-space-20);

        & > p {
          text-align: start;
          display: flex;

          span:first-child {
            display: inline-block;
            width: 60px;
            font-weight: bold;
          }
        }
      }
    }

    .inspection-area-data-rows {
      flex: 1;
      background-color: var(--chakra-colors-gray-50);
    }

    .no-data {
      flex-grow: 1;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-top: var(--chakra-space-20);
      padding-bottom: var(--chakra-space-20);
    }
  }
}

#inspection-sheet-container {
  background: white;
  height: 100%;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
}

#root:has(#app-container.user-account) {
  background-color: var(--chakra-colors-gray-50);
}

#inner-container.user-account {
  height: initial;

  .sidebar {
    align-items: start;
    position: sticky;
    top: 30px;
    height: fit-content;

    button {
      width: 100%;
      display: block;
      text-align: left;
      font-weight: normal;

      &:hover {
        text-decoration: none;
        color: white !important;
        background-color: var(--chakra-colors-primary-600) !important;
      }

      &.active {
        color: var(--chakra-colors-gray-700);
        background-color: var(--chakra-colors-gray-200);
        font-weight: bold;
      }
    }
  }

  .content-container {
    background-color: white;
    border-radius: var(--chakra-radii-lg);
    padding: var(--chakra-space-8);
    margin-left: var(--chakra-space-6);
    flex: 1;
  }
}

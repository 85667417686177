.section-title {
  position: relative;

  p {
    display: inline-block;
    position: relative;
    z-index: 5;
    padding-right: var(--chakra-space-2);
    font-weight: bold;
    white-space: nowrap;
  }
}

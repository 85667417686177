/* Fixes issue with too tall modals overflowing outside view */
.chakra-modal__content-container {
  align-items: safe center !important;

  &:has(.no-overlay) {
    pointer-events: none;

    .no-overlay {
      pointer-events: all;
    }
  }
}

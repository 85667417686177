.blueprint-viewer {
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blueprint > *:not(.blueprint-viewer) {
  touch-action: none;
}

.blueprint-viewer {
  touch-action: pan-x pan-y;
}

.blueprint-viewer.disabled-scroll {
  overflow: hidden;
}

.blueprint-viewer .react-pdf__Document {
  max-width: 100%;
  max-height: 100%;
  position: relative;
}

.blueprint-viewer .react-pdf__Page {
  background-color: black !important;
}

.blueprint-page:not(:last-child) {
  margin-bottom: 16px;
}

.blueprint-viewer::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.blueprint-viewer::-webkit-scrollbar-track {
  background-color: transparent;
}

.blueprint-viewer::-webkit-scrollbar-corner {
  background-color: transparent;
}

.blueprint-viewer::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: var(--chakra-colors-gray-600);
}

.hiddenCanvasElement {
  position: absolute;
  top: 0;
}

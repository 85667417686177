.inspection-area-data-rows {
  .grid-values {
    align-items: start;
    background-color: white;
    gap: 0;

    .grid-values-boxes-container {
      background-color: white;

      .grid-values-boxes {
        grid-gap: 0;
        grid-auto-rows: min-content;
        width: fit-content;
        margin: 0 auto;

        padding-top: var(--chakra-space-4);
        padding-bottom: var(--chakra-space-4);

        .grid-value-box {
          width: 70px;
          height: 70px;
          position: relative;
          text-align: center;
          border-bottom: 1px solid var(--chakra-colors-gray-200);
          padding: var(--chakra-space-2);

          .pill {
            min-width: 65%;
            margin-bottom: var(--chakra-space-2);
          }

          .diff {
            font-size: var(--chakra-fontSizes-xs);
            color: var(--chakra-colors-gray-400);
          }

          p {
            line-height: 100%;
          }

          &.failed {
            color: var(--chakra-colors-red-400);
            transition: color ease-in-out 300ms;

            .diff {
              color: var(--chakra-colors-red-400);
              transition: color ease-in-out 300ms;
            }

            .pill {
              transition:
                color ease-in-out 300ms,
                background-color ease-in-out 300ms;
            }
          }

          &.missing {
            font-weight: 400;
            color: var(--chakra-colors-gray-400);

            .pill {
              border-style: dashed;
            }
          }
        }
      }
    }
  }
}
